// modules
import { isValueOf } from '@/utils/zod'
import { temporaryImageData } from '@/models/Temporary'

// repositories
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'

// composables
import { isFetchError } from '@/composables/repository/useOhmyfetch'

export const useTemporaryUpload = () => {
  const repositoryFactory = useRepositoryFactory()
  const temporaryRepository = repositoryFactory.get('temporary')

  const uploadImage = async (file: File): Promise<string | null> => {
    try {
      const response = await temporaryRepository.post.postTemporaryUpload(file)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(temporaryImageData, response)) {
        console.error('An API response is different.')
      }

      return response.upload.file.url
    } catch (e: unknown) {
      // TODO: 共通処理に記述
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトする
      } else {
        console.error(e)
      }
      return null
    }
  }
  return {
    uploadImage,
  }
}
