import { z } from 'zod'

const fileData = z.object({
  url: z.string(), // url
})

export type FileData = z.infer<typeof fileData>

export const temporaryImageData = z.object({
  upload: z.object({
    id: z.number(),
    userId: z.number(),
    file: fileData,
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
})

export type TemporaryImageData = z.infer<typeof temporaryImageData>
